import {BaseViewModel} from "../../../../data/base/BaseViewModel";
import {SingleBaseProductPnlModel} from "../../models/SingleBaseProductPnlModel";

export class ProfitAndLossViewModel extends BaseViewModel {
    static _instance: ProfitAndLossViewModel = new ProfitAndLossViewModel();

    public static get instance() {
        if (ProfitAndLossViewModel._instance === undefined) {
            ProfitAndLossViewModel._instance = new ProfitAndLossViewModel();
        }
        return ProfitAndLossViewModel._instance;
    }


    async getSingleBaseProductPnlMonthly(params:  any ): Promise<SingleBaseProductPnlModel> {
        return await this.getApiService().getSingleBaseProductPnlMonthly(this.handleError, params);
    }
    async getSingleBaseProductPnlWeekly(params:  any ): Promise<SingleBaseProductPnlModel> {
        return await this.getApiService().getSingleBaseProductPnlWeekly(this.handleError, params);
    }








     PnlLabelObject = {
    revenue : 'Revenue (Sales)',
    cogs : 'COGS',
    gross_profit : 'Gross Profit',
    operating_expenses : 'Operating Expenses',
    net_profit : 'Net Profit',
    taxes : 'Taxes',
    net_profit_after_taxes : 'Net Profit after sales',
    profit_margin : 'Profit Margin',
    sales_growth_rate : 'Sales Growth Rate',
    cac : 'CAC',
    return_rate : 'Return Rate',
}

     PnlLabels: string[] = [
        'revenue',
        'cogs',
        'gross_profit',
        'operating_expenses',
        'net_profit',
        'taxes',
        'net_profit_after_taxes',
        'profit_margin',
        'sales_growth_rate',
        'cac',
        'return_rate',
    ];

}
