import React, {useMemo} from 'react';
import {NameOfMonth} from "../../../../../utils/common";
// import EXPORT_ICON from "./../../../../../../public/icons/import.png"
import moment from "moment";
// import * as XLSX from 'xlsx';


type SalesData = any;

enum SalesNamesEnum {
    revenue = 'Revenue (Sales)',
    cogs = 'COGS',
    gross_profit = 'Gross Profit',
    operating_expenses = 'Operating Expenses',
    net_profit = 'Net Profit',
    taxes = 'Taxes',
    net_profit_after_taxes = 'Net Profit after sales',
    profit_margin = 'Profit Margin',
    sales_growth_rate = 'Sales Growth Rate',
    cac = 'CAC',
    return_rate = 'Return Rate',
}

interface SalesTableProps {
    monthData: any;
    isYear?: boolean;
    isWeek?: boolean;
}

interface GetDateLabelsProps {
    value: string;
}

const ProfitAndLossTable: React.FC<SalesTableProps> = ({
                                                           monthData,
                                                           isYear = false,
                                                           isWeek = false
                                                       }) => {
    const salesNames: string[] = [
        'revenue',
        'cogs',
        'gross_profit',
        'operating_expenses',
        'net_profit',
        'taxes',
        'net_profit_after_taxes',
        'profit_margin',
        'sales_growth_rate',
        'cac',
        'return_rate',
    ];

    // const [exportData, setExportData] = useState([]);

    // Function to export data to Excel
    
    // const exportToXLSX = (data) => {
    //     // Create an array of data
    //     const dataArray = salesNames.map((salesName) => ({
    //         'Sales Name': SalesNamesEnum[salesName as keyof typeof SalesNamesEnum],
    //         ...months.reduce((acc, month) => {
    //             acc[getDateLables({ value: month })] = data[salesName][month] || '';
    //             return acc;
    //         }, {}),
    //     }));
    //     // Create a worksheet
    //     const ws = XLSX.utils.json_to_sheet(dataArray);
    //     // Set additional styles for rows and columns (adjust values as needed)
    //     ws['!rows'] = [{ hpt: 30, hpx: 30 }]; // Add height for rows (e.g., 20 points)
    //     ws['!cols'] = [{ wch: 20 }, { wch: 15 }, { wch: 25 },{ wch: 25 },{ wch: 25 },{ wch: 25 },{ wch: 25 },{ wch: 25 },{ wch: 25 },{ wch: 25 },{ wch: 25 },{ wch: 25 }]; // Add width for columns (e.g., 10 characters)
    //     // Create a workbook and add the worksheet
    //     const wb = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(wb, ws, 'SalesData');
    //     // Generate the Excel file
    //     const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'base64' });
    //     // Trigger the download
    //     XLSX.writeFile(wb, 'profit-loss.xlsx');
    // };

    const months: string[] = monthData && Object.keys(monthData?.data);

    const initialData: SalesData = useMemo(() => {
        const data: SalesData = {};
        salesNames?.forEach((fieldName) => {
            data[fieldName] = {};
            months?.forEach((month) => {
                // Use object fetched through API in place of Data
                const monthList = monthData?.data[month][0];
                const value = monthList ? monthList[fieldName] : '';
                data[fieldName][month] = parseFloat(value).toFixed(2);
            });
        });

        return data;
    }, [months]);


   

    
    const getDateLables: React.FC<GetDateLabelsProps> = ({value}) => {
        if (isYear) {
            return value && value
        }
        if (isWeek) {
            return value && moment(value).format("DD MMM YYYY") + " " + moment(value).format("dddd")
        }
        return NameOfMonth(value)
    }

    return (
        <div className='w-full  mb-5'>
            <div className="flex flex-col gap-4">
                {/*<button onClick={()=> {*/}
                {/*    exportToXLSX(initialData)*/}
                {/*}}>Export to test</button>*/}

                <div className="border-[2px] border-[#DCDADA] rounded-xl ">
                    <table
                        className='w-full border-collapse  rounded-md'
                        style={{borderRadius: '8px !impotent'}}
                    >
                        <thead>
                        <tr>
                            <th className='p-2 text-center text-white'></th>
                            {months?.map((month, index) => {
                                return (
                                    <th key={month}
                                        className={`${index !== months.length && "border-r-0 "} border-[#DCDADA] border-x  border-b p-2 text-center `}>
                                        <span className="text-[#000] text-lg">{getDateLables({value: month})}</span>

                                    </th>
                                )
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        {salesNames?.map((salesName, index2) => {
                            const salesNameString =
                                SalesNamesEnum[salesName as keyof typeof SalesNamesEnum];
                            return (
                                <tr key={index2}>
                                    <td className={`${index2 !== salesName.length - 1 ? "border-r-0" : "border-b-0 "} border-[#DCDADA]  border-y  p-2 text-left font-semibold`}>

                                        <span className="text-[#000] text-lg">{salesNameString}</span>

                                    </td>
                                    {months?.map((year, index) => (
                                        <td
                                            key={index}
                                            className={`${index === months.length - 1 ? "border-r-0" : " "} ${index2 === salesNames.length - 1 ? "" : "border-b  border-gray-400 "} border-[#DCDADA] border-x p-2 text-center `}
                                        >
                                            {/*₹{initialData[salesName][year] && parseFloat(initialData[salesName][year]).toFixed(2)}*/}
                                            <span className="text-[#000] text-sm font-medium">{initialData[salesName][year] && `${Math.round(parseFloat(initialData[salesName][year]) * 100) / 100}`}</span>

                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ProfitAndLossTable;