import React, { useEffect, useState } from 'react';
import ZL_LOGO from '../../../../assets/icons/zebralogo.png';
import AreaTypeChart from '../../../../components/all-types-chart/area-chart/AreaChart';
import { modifyChannelName } from '../../../../utils/common';
import { BooksViewModel } from '../../BooksViewModel';
// import {BaseProductPnlModulesResponseModel} from "../../models/BaseProductPnlModulesResponseModel";
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/loader/Loader';
import GroupButton from '../../../../components/group-buttons/GroupButton';
import moment from 'moment/moment';
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
// import LinesChart from "../../../../components/lines-chart/LinesCharts.tsx";
// import BarChart from "../../../../components/bar-chart/BarChart.tsx";
// import MultiChart from "./MultiChart.tsx";

const model = BooksViewModel.instance;

const OverView: React.FC = () => {
  const [baseProductModules, setBaseProductModules] = useState<any>();
  const { id } = useParams();
  const [isDateSelect, setIsDateSelect] = useState<number>();
  const [isloading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pickerVisible, setPickerVisible] = useState(false);
  const [isCallDateRange, setIsCallRange] = useState<boolean>();

  const FirstDateOfCurrentMonth = (value: any) => {
    const firstDate = moment().startOf('month');
    const lastMonthFirstDate = firstDate.subtract(value, 'months');
    const formattedDate = lastMonthFirstDate.format('YYYY-MM-DD');
    return formattedDate;
  };
  console.log(baseProductModules, 'baseProductModules');
  const isEmpty = !isDateSelect && !startDate && !endDate;

  useEffect(() => {
    const getSingleProductModules = async () => {
      try {
        setIsLoading(true);
        const currentDate = moment().startOf('month');
        const currentFormattedDate = currentDate.format('YYYY-MM-DD');

        const toDate = FirstDateOfCurrentMonth(isDateSelect);

        const params = {
          base_product_id: id,
          ...(isDateSelect
            ? {
                from: toDate,
                to: currentFormattedDate,
              }
            : startDate && endDate
            ? {
                from: moment(decodeURIComponent(startDate)).format(
                  'YYYY-MM-DD'
                ),
                to: moment(decodeURIComponent(endDate)).format('YYYY-MM-DD'),
              }
            : null),
        };
        console.log(params, 'params');
        const response = await model.getBaseSingleProductWithModule(
          isEmpty ? { base_product_id: id } : params
        );
        console.log(response, 'response')
        if (response && response.success) {
          setBaseProductModules(response?.data?.data[0]);
          if (startDate && endDate) {
            setStartDate(null);
            setEndDate(null);
          }
        }
      } catch (error) {
        console.error('Login error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isDateSelect || startDate || endDate || id) {
      getSingleProductModules();
    }
  }, [id, isDateSelect, isCallDateRange, startDate, endDate, isEmpty]);

  const productItems = baseProductModules && baseProductModules?.product_items || []; // Assuming there's only one item in the data array
  console.log(productItems,"productItems")
  const channelNames = productItems && productItems?.map(
    (item: { channel: { name: any } }) => item?.channel?.name
  );
  console.log(channelNames,"channelNames");

  const totalQuantity = productItems && productItems?.map(
  (item: { datedSales?: { total_quantity?: any }[] }) =>
    item?.datedSales?.[0]?.total_quantity
);

  console.log(totalQuantity,'totalQuantity')
  const chartDataForBar: {
    series: { data: any; name: string }[];
    options: {
      plotOptions: { bar: { width: number } };
      xaxis: { categories: any; labels: { show: boolean } };
      title: { text: string; align: string };
      chart: { type: string; height: number };
      yaxis: {
        title: { text: string };
        labels: { formatter: (value: any) => string };
      };
    };
  } = {
    options: {
      chart: {
        type: 'bar', // Set the chart type to 'bar' for a bar graph
        height: 350, // Adjust the height as needed
      },
      plotOptions: {
        bar: {
          width: 1, // Set the line thickness to 1 (very thin)
        },
      },
      xaxis: {
        categories: channelNames, // Use channel names for X-axis
        labels: {
          show: true, // Show labels on the X-axis
        },
      },
      yaxis: {
        title: {
          text: 'Total Quantity', // Y-axis label
        },
        labels: {
          formatter: function (value) {
            return formatNumberForAmount(value); // Format Y-axis labels with K or M suffixes
          },
        },
      },
      title: {
        text: '',
        align: 'center',
      },
    },
    series: [
      {
        name: 'Total Quantity',
        data: totalQuantity, // Use total amount for Y-axis data
      },
    ],
  };

  const totalAmounts = productItems?.map(
    (item: { datedSales: { total_amount: any }[] }) =>
      item?.datedSales[0]?.total_amount
  );

  const chartDataForLine = {
    series: [
      {
        name: 'Total Revenue',
        data: totalAmounts, // Use total amount for Y-axis data
      },
    ],
    options: {
      chart: {
        type: 'line', // Set the chart type to 'line'
        height: 350, // Adjust the height as needed
      },
      plotOptions: {
        line: {
          width: 1, // Set the line thickness to 1 (very thin)
        },
      },
      xaxis: {
        categories: channelNames, // Use channel names for X-axis
        labels: {
          show: true, // Show labels on the X-axis
        },
      },
      yaxis: {
        title: {
          text: 'Total Amount', // Y-axis label
        },
        labels: {
          formatter: function (value: any) {
            return formatNumberForAmount(value); // Format Y-axis labels with K or M suffixes
          },
        },
      },
      title: {
        text: 'Channel-wise Total Revenue',
        align: 'center',
      },
    },
  };
  const { series, options } = chartDataForLine;
  console.log(series, options, "line203");
  
  const data = series[0]?.data?.map((value: any, index: number) => ({
    name: options.xaxis.categories[index],
    'Total Amount': value,
  }));
  console.log(data,'line209')
  

  function formatNumberForAmount(value: any) {
    if (value >= 1e6) {
      return (value / 1e6).toFixed(1) + 'M';
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(1) + 'K';
    }
    return value;
  }

  /* under development testing for overview chart*/

  const salesData = [
    {
      date: '2023-10-12',
      totalSales: 10000,
      authorRoyalty: 1000,
    },
    {
      date: '2023-12-12',
      totalSales: 2000,
      authorRoyalty: 30000,
    },
    {
      date: '2023-11-12',
      totalSales: 40000,
      authorRoyalty: 4000,
    },
    {
      date: '2023-9-12',
      totalSales: 5000,
      authorRoyalty: 50000,
    },
  ];

  /* under development testing for overview chart*/

  const singleChannelChart = salesData.map((item) => ({
    name: item?.date, // Use the date as the x-axis label
    Sales: item?.totalSales,
    Quantity: item?.authorRoyalty,
  }));

  return (
    <>
      {isloading ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-4">
          <div className="flex justify-between">
            <div className="flex flex-row items-center gap-3 px-4">
              <b className="text-black font-Poppins text-lg  whitespace-nowrap">
                {baseProductModules?.name}
              </b>
              <p className="flex items-center justify-center">by</p>
              <div className="flex gap-2 items-center">
                <div className="w-5 h-5">
                  <img src={ZL_LOGO} alt="" />
                </div>
                <b className="text-black font-Poppins text-sm  leading-24">
                  Zebralearn
                </b>
              </div>
            </div>

            <GroupButton
              setIsCallRange={setIsCallRange}
              isCallDateRange={isCallDateRange}
              setStartDate={setStartDate}
              startDate={startDate}
              endDate={endDate}
              pickerVisible={pickerVisible}
              setEndDate={setEndDate}
              setPickerVisible={setPickerVisible}
              setIsDateSelect={setIsDateSelect}
              isDateSelect={isDateSelect}
            />
          </div>
          {/*left side contents*/}

          <div className="p-4 rounded-lg border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark grid grid-cols-2">
            {/* Your content for each section */}
            <div className="col-span-1 p-5.5">
              <div className="flex flex-col gap-8">
                <b className="text-black font-Poppins text-xl">
                  {' '}
                  Analytics Overview{' '}
                </b>
                <div className=" flex flex-col gap-2">
                  <div className="flex  gap-6">
                    <div className="flex gap-2 w-50">
                      <p className="text-black font-Poppins text-lg font-medium">
                        Total Revenue amount
                      </p>
                    </div>
                    <span className="text-lg">:</span>
                    <div className="flex gap-2">
                      <p className="text-[#9F9F9F] font-Poppins text-lg font-medium leading-24">
                        ₹
                      </p>
                      <p className="text-black font-Poppins text-lg font-medium leading-24">
                        {baseProductModules?.totalSales.toLocaleString('en-IN')}
                      </p>
                    </div>
                  </div>
                  <div className="flex  gap-6">
                    <div className="flex gap-2 w-50">
                      <p className="text-black font-Poppins text-lg font-medium">
                        Total Expenses
                      </p>
                    </div>
                    <span className="text-lg">:</span>
                    <div className="flex gap-2">
                      <p className="text-[#9F9F9F] font-Poppins text-lg font-medium leading-24">
                        ₹
                      </p>
                      <p className="text-black font-Poppins text-lg font-medium leading-24">
                        {Math.round(
                          baseProductModules?.totalExpenses
                        ).toLocaleString('en-IN')}
                      </p>
                    </div>
                  </div>
                  <div className="flex  gap-6">
                    <div className="flex gap-2 w-50">
                      <p className="text-black font-Poppins text-lg font-medium">
                        Profit
                      </p>
                      <p className="text-[#9F9F9F] font-Poppins text-xl font-medium">
                        ({Math.round(baseProductModules?.percentage)}%)
                      </p>
                    </div>
                    <span className="text-lg">:</span>
                    <div className="flex gap-2">
                      <p className="text-[#9F9F9F] font-Poppins text-lg font-medium leading-24">
                        ₹
                      </p>
                      <p className="text-black font-Poppins text-lg font-medium leading-24">
                        {Math.round(
                          baseProductModules?.profitOrLoss
                        ).toLocaleString('en-IN')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1 p-2">
              <div className="grid grid-cols-2 gap-4">
                {baseProductModules?.product_items.map(
                  (items: any, index: number) => (
                    <div key={index} className="col-span1">
                      <div className="bg-[#F1F1F1] rounded-xl flex flex-col gap-2 p-6 w-auto">
                        <div className="flex flex-row gap-4 items-center">
                          <div className="h-5 w-5">
                            <img
                              src={`/icons/${items?.channel?.id}.png`}
                              alt=""
                            />
                          </div>
                          <b className=" text-black font-Poppins text-lg">
                            {/*{items.channel.name === "Woo-commerce" ? "ZebraLearn" : items.channel.name}*/}
                            {modifyChannelName(items?.channel?.name, index)}
                          </b>
                        </div>
                        <div className="flex flex-row gap-8 items-center ">
                          <div className="flex gap-3">
                            <p className="text-black font-Poppins text-sm font-medium">
                              Quantity
                            </p>
                            <span className="text-sm">:</span>
                            <div>
                              <p className="text-grey font-Poppins text-sm font-medium leading-24">
                                {items?.datedSales[0]?.total_quantity}
                              </p>
                            </div>
                          </div>
                          <div className="flex  gap-3">
                            <p className="text-black font-Poppins text-sm font-medium">
                              Amount
                            </p>
                            <span className="text-sm">:</span>
                            <div className="flex ">
                              <p className="text-grey font-Poppins text-sm font-medium leading-24">
                                ₹
                              </p>
                              <p className="text-grey font-Poppins text-sm font-medium leading-24">
                                {Math.round(
                                  items?.datedSales[0]?.total_amount
                                ).toLocaleString('en-IN')}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
            <div className="col-span-8">
              <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
                <div className="flex flex-col gap-3">
                  <div className="w-full">
                    <b className="text-black font-Poppins text-lg">
                      Analytics Overview
                    </b>
                    <p className="text-sm font-medium">
                      Standalone Figures in Amount
                    </p>
                  </div>
                  <ResponsiveContainer
                    width="100%"
                    height={options?.chart?.height}
                  >
                    <LineChart
                      width={500}
                      height={options?.chart?.height}
                      data={data}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis
                        tickFormatter={formatNumberForAmount} // Format Y-axis labels with K or M suffixes
                      />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="Total Amount"
                        stroke="#8884d8"
                        activeDot={{ r: 8 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>

            <div className="col-span-4">
              <AreaTypeChart
                type="bar"
                options={chartDataForBar?.options}
                series={chartDataForBar?.series}
                graphTitle={'Analytics Overview'}
                graphSubTitle={'Standalone Figures in Quantity'}
                height={340}
              />
            </div>
          </div>

          {/* under development testing for overview chart*/}
          <div
            style={{ width: '100%', height: 400 }}
            className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8"
          >
            <b className="text-[#000]">This Graph under development</b>
            <ResponsiveContainer>
              <ComposedChart
                width={500}
                height={400}
                data={singleChannelChart}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="Sales"
                  fill="#0BD1F4"
                  stroke="#8884d8"
                />
                <Bar dataKey="Quantity" barSize={20} fill="#25D228" />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </>
  );
};

export default OverView;
