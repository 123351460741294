import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TabSwitcher from '../../../components/tabs-switcher/TabSwticher';
import OverView from './overview/OverView';
import ProfitAndLoss from './profit-and-loss/ProfitAndLoss';
import { FaArrowLeftLong } from 'react-icons/fa6';

const BookDetailsView = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(1);
  //   const [selectedYear, setSelectedYear] = useState('2023'); // Default to "All" or a specific year
  // const { id } = useParams();
  const tabs = [
    { id: 1, label: 'Overview' },
    { id: 2, label: 'Profit & Loss' },
    { id: 3, label: 'Sales' },
    { id: 4, label: 'Marketing' },
    { id: 5, label: 'Other Expenses' },
    { id: 6, label: 'Inventory' },
  ];

  const handleChangeTabValue = (newValue: number) => {
    setTabValue(newValue);
    const selectedTab: any = tabs[newValue];
    navigate(selectedTab?.id);
  };

  return (
    <>
      <div className="card-header border-0">
        <div className="flex justify-start gap-10">
          <div className="flex flex-row gap-2 items-center text-black mb-2 ">
            <FaArrowLeftLong
              className="cursor-pointer font-bold "
              onClick={() => navigate(-1)}
            />
            <p className="text-black font-poppins text-24 font-semibold">
              Book Analytics
            </p>
          </div>
        </div>
        <TabSwitcher
          tabs={tabs}
          activeTab={tabValue}
          onTabChange={handleChangeTabValue}
        />
        {tabValue === 1 && <OverView />}
        {tabValue === 2 && <ProfitAndLoss />}
        {tabValue === 3 && 'Sale'}
        {tabValue === 4 && 'Marketing'}
        {tabValue === 5 && 'Other Expenses'}
        {tabValue === 6 && 'Inventory'}
      </div>
    </>
  );
};

export default BookDetailsView;
