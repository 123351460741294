import React from 'react';
import ReactApexChart from 'react-apexcharts';
interface AreaTypeChartProp{
    graphTitle:string;
    graphSubTitle:string
    type:any
    options:any
    series:any
    height:any
}

const AreaTypeChart: React.FC<AreaTypeChartProp> = ({graphTitle,graphSubTitle,type,options,series,height}) => {

    return (
        <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
            <div className="flex flex-wrap items-start justify gap-3 sm:flex-nowrap">
                <div className="flex w-full flex-wrap gap-3 sm:gap-5">
                    <div className="flex min-w-47.5">
                        <div className="w-full">
                            <b className="text-black font-Poppins text-lg">{graphTitle}</b>
                            <p className="text-sm font-medium">{graphSubTitle}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div id="chartOne" className="-ml-5">
                    <ReactApexChart
                        options={options}
                        series={series}
                        type={type}
                        height={height}
                    />
                </div>
            </div>
        </div>
    );
};

export default AreaTypeChart;
