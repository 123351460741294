import React, {useEffect, useState} from 'react';
import ZL_LOGO from "../../../../assets/icons/zebralogo.png"
import AreaTypeChart from "../../../../components/all-types-chart/area-chart/AreaChart";
import {ProfitAndLossViewModel} from "./ProfitAndLossViewModel";
import ProfitAndLossTable from "./tables/ProfitAndLossTable";
import {BooksViewModel} from "../../BooksViewModel";
import {SingleBaseProductPnlModel} from "../../models/SingleBaseProductPnlModel";
// import {BaseProductPnlModulesResponseModel} from "../../models/BaseProductPnlModulesResponseModel";
import {useParams} from "react-router-dom";
import Loader from "../../../../components/loader/Loader";
import {formatNumberForAmount, generateSortedMonthNames} from "../../../../utils/common";
import EXPORT_ICON from "../../../../assets/icons/import.png";
import {BiChevronDown} from "react-icons/bi";

const model = ProfitAndLossViewModel.instance;
const modelForBooksViewModel = BooksViewModel.instance;

const ProfitAndLoss: React.FC = () => {
    const [singleBaseProductYearly, setSingleBaseProductYearly] = useState<any>();
    const [baseProductModules, setBaseProductModules] = useState<any>();
    const [singleBaseProductMonthly, setSingleBaseProductMonthly] = useState<any>();
    const {id} = useParams()
    const [selectedMonth, setSelectedMonth] = useState('All Years')
    const [isloading, setIsloading] = useState(false)

    // excel downlaod

    //this api for  basemodule data
    useEffect(() => {
        const getSingleProductModules = async () => {
            try {
                setIsloading(true)
                const params = {
                    base_product_id: id
                };
                const response = await modelForBooksViewModel.getBaseSingleProductWithModule(params);
                if (response && response.success) {
                    setBaseProductModules(response?.data[0])
                }
            } catch (error) {
                console.error("Login error:", error);
            } finally {
                setIsloading(false)
            }
        };
        getSingleProductModules();
    }, [id]);
    //this is for yearlywise api call
    useEffect(() => {
        const getSingleProductPnlYearly = async () => {
            try {
                setIsloading(true)
                const params = {
                    base_product_id: id,
                };
                const response = await modelForBooksViewModel.getSingleBaseProductPnlYearly(params);
                if (response && response.success) {
                    setSingleBaseProductYearly(response as SingleBaseProductPnlModel)
                }
            } catch (error) {
                console.error("Login error:", error);
            } finally {
                setIsloading(false)
            }
        };
        getSingleProductPnlYearly();
    }, [id, selectedMonth]);

    const onProductFilterByMonthly = async () => {
        try {
            setIsloading(true)
            const params = {
                base_product_id: id,
                // year: selectedYear, // Use the calculated start year
            };
            const response = await model.getSingleBaseProductPnlMonthly(params);
            if (response && response.success) {
                setSingleBaseProductMonthly(response);
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setIsloading(false)
        }
    };
    useEffect(() => {
        onProductFilterByMonthly();
    }, []);
    // const onProductFilterByYearly = async (selectedYear: any, id: any) => {
    //     try {
    //         setIsloading(true)
    //         const params = {
    //             base_product_id: id,
    //             number_of_years: selectedYear,
    //         };
    //         const response = await modelForBooksViewModel.getSingleBaseProductPnlYearly(params);
    //         setSingleBaseProductYearly(response);
    //     } catch (error) {
    //         console.error("Error:", error);
    //     } finally {
    //         setIsloading(false)
    //     }
    // };
    // const onSelectYearProduct = (e: any) => {
    //     const selectedValue = e.target.value;
    //     onProductFilterByYearly(selectedValue, id);
    // };
    const onSelectByMonthly = async (selectedMonth: any, id: any) => {
        try {
            setIsloading(true)
            const params = {
                base_product_id: id,
                year: selectedMonth, // Use the calculated start year
            };
            const response = await model.getSingleBaseProductPnlMonthly(params);
            if (response && response.success) {
                setSingleBaseProductMonthly(response);
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setIsloading(false)
        }
    };
    useEffect(() => {
        onSelectByMonthly(selectedMonth, id)
    }, [selectedMonth]);
    const onSelectMonthlyProduct = (e: any) => {

        const selectedValue = e.target.value;
        setSelectedMonth(selectedValue)
    }

    //graphs logic for yearly wise on profit and loss page
    const years = singleBaseProductYearly?.data && Object.keys(singleBaseProductYearly?.data) || [];
    const chartDataForYearly: {
        series: { data: any; name: string }[];
        options: {
            plotOptions: { bar: { horizontal: boolean } };
            xaxis: { categories: any; labels: { show: boolean } };
            title: { text: string; align: string };
            chart: { type: string; height: number };
            yaxis: { title: { text: string }, labels: { formatter: (value: any) => string } }; // Add labels formatter
        }
    } = {
        options: {
            chart: {
                type: 'bar', // Set the chart type to 'bar' for a bar graph
                height: 350, // Adjust the height as needed
            },
            plotOptions: {
                bar: {
                    horizontal: false, // Set to true if you want horizontal bars
                },
            },
            xaxis: {
                categories: years, // Use channel names for X-axis
                labels: {
                    show: true, // Show labels on the X-axis
                },
            },
            yaxis: {
                title: {
                    text: 'Total Amount', // Y-axis label
                },
                labels: {
                    formatter: function (value) {
                        return formatNumberForAmount(value); // Format Y-axis labels with K or M suffixes
                    },
                },
            },
            title: {
                text: 'Yearly wise Profit margin  and gross profit',
                align: 'center',
            },
        },
        series: [
            {
                name: 'Profit Margin',
                data: years?.map((year:number) => {
                    // Fetch profit_margin for each year (replace 'data' with your actual data source)
                    const profitMargin = singleBaseProductYearly?.data[year][0]?.profit_margin;
                    return Math.round(profitMargin);
                }),
            },
            {
                name: 'Gross Profit',
                data: years?.map((year:number) => {
                    // Fetch gross_profit for each year (replace 'data' with your actual data source)
                    const grossProfit = singleBaseProductYearly?.data[year][0].gross_profit;
                    return Math.round(grossProfit);
                }),
            },
        ],
    };

    // bar graph for yearly tables
    // const pnlDataLabels = ['revenue', 'cogs', 'profit_margin', 'gross_profit', 'operating_expenses', 'profitOrLoss', 'sales_growth_rate', 'return_rate'];
    const pnlDataLabels = ['profit_margin', 'gross_profit'];
// Create an array to store the series data
    const yearlySeriesDataForBar = pnlDataLabels.map((dataType:any) => ({
        name: dataType,
        data: years?.map((year:number) => {
            // Fetch data for the current data type and year (replace 'data' with your actual data source)
            const dataValue = singleBaseProductYearly?.data[year][0][dataType];
            return Math.round(dataValue);
        }),
        color: getSeriesColor(dataType), // Define a function to get the color based on data type
    }));

    type DataType = 'revenue' | 'cogs' | 'profit_margin' | 'gross_profit' | 'operating_expenses' | 'profitOrLoss' | 'sales_growth_rate' | 'return_rate';


    function getSeriesColor(dataType: DataType) {
        // Define a color mapping for each data type
        const colorMap: Record<DataType, string> = {
            revenue: '#FF5733',
            cogs: '#00BFFF',
            profit_margin: '#FE5512',
            gross_profit: '#86D287',
            operating_expenses: '#FFD700',
            profitOrLoss: '#00FF00',
            sales_growth_rate: '#FF1493',
            return_rate: '#008080',
        };
    
        // Return the color for the given data type or a default color
        return colorMap[dataType] || '#000000';
    }
    const yearlyBarChartData: {
        series: { data: any; name: string }[];
        options: {
            plotOptions: { bar: { horizontal: boolean } };
            xaxis: { categories: any; labels: { show: boolean } };
            title: { text: string; align: string };
            chart: { type: string; height: number, };
            yaxis: { title: { text: string }, labels: { formatter: (value: any) => string } }; // Add labels formatter
        }
    } = {
        options: {
            chart: {
                type: 'bar', // Set the chart type to 'bar' for a bar graph
                height: 550, // Adjust the height as needed
            },
            plotOptions: {
                bar: {
                    horizontal: false, // Set to true if you want horizontal bars
                },
            },
            xaxis: {
                categories: years, // Use channel names for X-axis
                labels: {
                    show: true, // Show labels on the X-axis
                },
            },
            yaxis: {
                title: {
                    text: 'Total Amount', // Y-axis label
                },
                labels: {
                    formatter: function (value) {
                        return formatNumberForAmount(value); // Format Y-axis labels with K or M suffixes
                    },
                },
            },
            title: {
                text: 'Channel-wise Total Revenue',
                align: 'center',
            },
        },
        series: yearlySeriesDataForBar,
    };

    //graphs logic for monthly wise on profit and loss page
    const months = singleBaseProductMonthly?.data && Object.keys(singleBaseProductMonthly?.data);
    
    const chartDataForMonthly: {
        series: any;
        options: {
            plotOptions: { bar: { horizontal: boolean } };
            xaxis: { categories: any; labels: { show: boolean } };
            title: { text: string; align: string };
            chart: { type: string; height: number };
            yaxis: { title: { text: string }, labels: { formatter: (value: any) => string } }; // Add labels formatter
        }
    } = {
        options: {
            chart: {
                type: 'area', // Set the chart type to 'bar' for a bar graph
                height: 350, // Adjust the height as needed
            },
            plotOptions: {
                bar: {
                    horizontal: false, // Set to true if you want horizontal bars
                },
            },
            xaxis: {
                categories: generateSortedMonthNames(months), // Use channel names for X-axis
                labels: {
                    show: true, // Show labels on the X-axis
                },
            },
            yaxis: {
                title: {
                    text: 'Total Amount', // Y-axis label
                },
                labels: {
                    formatter: function (value) {
                        return formatNumberForAmount(value); // Format Y-axis labels with K or M suffixes
                    },
                },
            },
            title: {
                text: 'Monthly wise Profit margin  and gross profit',
                align: 'center',
            },
        },
        series: [
            {
                name: 'Profit Margin',
                data: months?.map((year:number) => {
                    // Fetch profit_margin for each year (replace 'data' with your actual data source)
                    const profitMargin = singleBaseProductMonthly?.data[year][0]?.profit_margin;
                    return Math.round(profitMargin);
                }),
                color: '#FE5512', // Set the color for the "Profit Margin" series
            },
            {
                name: 'Gross Profit',
                data: months?.map((year:number) => {
                    // Fetch gross_profit for each year (replace 'data' with your actual data source)
                    const grossProfit = singleBaseProductMonthly?.data[year][0]?.gross_profit;
                    return Math.round(grossProfit);
                }),
                color: '#86D287', // Set the color for the "Gross Profit" series

            },
        ],
    };

    // line graph for all sales labels


    return (
        <>
            {!isloading ?
                <div className="flex flex-col gap-4">
                    <div className="flex justify-between">
                        <div className="flex flex-row items-center gap-3">
                            <b className="text-black font-Poppins text-lg  whitespace-nowrap">
                                {baseProductModules?.name}
                            </b>
                            <p className="flex items-center justify-center">
                                by
                            </p>
                            <div className="flex gap-2 items-center">
                                <div className="w-5 h-5">
                                    <img src={ZL_LOGO} alt=""/>
                                </div>
                                <p className="text-black font-Poppins text-lg font-medium leading-24">
                                    Zebralearn
                                </p>
                            </div>
                        </div>

                    </div>
                    <div
                        className="rounded-lg border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark p-4">
                        <div className="flex justify-between mb-4">
                            <div className="flex flex-col">
                                {selectedMonth === 'All Years' ?
                                    <b className="text-black font-Poppins text-lg">Profit & Loss Yearly</b>
                                    : <b className="text-black font-Poppins text-lg">Profit & Loss Monthly</b>
                                }
                                <span>Standalone Figures in <b>₹</b></span>
                            </div>
                            <div className="flex flex-row gap-4">
                                <div className="relative inline-block">
                                    <select
                                        onChange={onSelectMonthlyProduct}
                                        className="flex rounded-md border border-b border-[#E6E6E6] py-3 px-10 font-medium text-black bg-white  cursor-pointer appearance-none"
                                    >
                                        <option value={'All Years'}>All Year</option>
                                        <option value="2023">2023</option>
                                        <option value="2022">2022</option>
                                        <option value="2021">2021</option>
                                        <option value="2020">2020</option>
                                        <option value="2019">2019</option>
                                    </select>
                                    <div
                                        className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                        <BiChevronDown className="text-2xl text-[#000]"/>

                                    </div>
                                </div>

                                <button
                                        className="inline-flex items-center justify-center gap-2 px-4  rounded-md bg-black  text-center font-medium text-white hover:bg-opacity-90 "
                                >
                                    <img src={EXPORT_ICON} alt=""/>
                                    Export to excel
                                </button>
                            </div>
                        </div>
                        {selectedMonth === 'All Years' ?
                            isloading ? <Loader/> :
                                <ProfitAndLossTable monthData={singleBaseProductYearly} isYear={true}/>
                            : isloading ? <Loader/> : <ProfitAndLossTable monthData={singleBaseProductMonthly}/>
                        }
                    </div>

                    {/*graph representation */}

                    {selectedMonth === 'All Years' ? (
                        <div className="flex flex-col gap-4">
                            <AreaTypeChart
                                type="area"
                                options={chartDataForYearly.options}
                                series={chartDataForYearly.series}
                                graphTitle={"Profit & Loss by Yearly"}
                                graphSubTitle={"Standalone Figures in Quantity"}
                                height={500}
                            />
                            <AreaTypeChart
                                type="bar"
                                options={yearlyBarChartData.options}
                                series={yearlyBarChartData.series}
                                graphTitle={"Analytics Overview"}
                                graphSubTitle={"Standalone Figures in Quantity"}
                                height={500}
                            />

                        </div>
                    ) : (
                        <div>

                            <AreaTypeChart type="area" options={chartDataForMonthly.options}
                                           series={chartDataForMonthly.series}
                                           graphTitle={"Profit & Loss for monthly"}
                                           graphSubTitle={"Standalone Figures in Quantity"} height={500}/>

                        </div>
                    )}

                </div>
                :
                <>
                    <Loader/>
                </>}
        </>
    );
};

export default ProfitAndLoss;

